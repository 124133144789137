/*!

=========================================================
* Argon Dashboard React - v1.2.1
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  FormGroup,
  Form,
  Input,
  Container,
  Row,
  Col, InputGroup, InputGroupAddon, InputGroupText,
} from "reactstrap";
import ReactDatetime from "react-datetime";
import Select2 from "react-select2-wrapper";
import {useHistory} from "react-router-dom";
import axios from "axios";


// core components
// import UserHeader from "components/Headers/UserHeader.js";
import React, {useEffect, useState} from "react";
import Daily_sheet_header from "../../components/Headers/Detail_sheet_header";
import {getJwt} from "../helpers/jwt";



function Daily_sheet()  {

  const getUser = () => {
    const userJson = localStorage.getItem("user");
    if (userJson) {
      return JSON.parse(userJson);
    }
    return null;
  };

  const [submitButtonLabel, setSubmitButtonLabel] = useState("Submit");
  const [submitButtonLoading, setSubmitButtonLoading] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [options, setOptions] = useState([]);
  const [trucks, setTrucks] = useState([]);
  const [stone, setStone] = useState([]);
  const [dirt, setDirt] = useState([]);
  const [dirt_type, setDirt_type] = useState([]);
  const [stab, setStab] = useState([]);
  const [stab_per, setStabper] = useState([]);


  const resetForm = () => {
    setDailySheet({
      date_of_activity: "",
      job: "",
      weatherType: "",
      tempHigh: "",
      tempLow: "",
      precip: "",
      precipInc: "",
      narrative: "",
      trucks: "",
      stone: "",
      tons: "",
      dirt1: "",
      dirt2: "",
      dirt3: "",
      stab1: "",
      stab2: "",
      stab3: "",
      stab4: "",
    });
  };
  useEffect(() => {
    const jwt = getJwt();
    if (!jwt) {
      history.push("/auth/login");
    }

    const user_det = getUser();
    if (!user_det) {
      axios
          .get("https://reports.schuepferinc.com/dbapp/decode_token", {
            headers: { 'x-access-token': jwt }
          })
          .then((res) => {
            const user = res.data;

            // eslint-disable-next-line no-lone-blocks
            {
              localStorage.setItem("user", JSON.stringify(res.data));
            }
            setDailySheet((prev) => ({ ...prev, user, loading: false }));
            // console.log(user);
          })
          .catch((err) => {
            // log request error and prevent access to undefined state
            setDailySheet((prev) => ({ ...prev, loading: false, error: true }));
            console.error(err);
          });
    }
  }, []); // The empty array as the second argument ensures this effect only runs once when the component mounts
  //
  //
  //
  //
  useEffect(() => {
    // Make a GET request to your Flask API
    fetch('https://reports.schuepferinc.com/dbapp/static-data')
        .then((response) => response.text()) // Read the response as text
        .then((data) => {
          // Split the comma-separated string into an array
          const optionsArray = data.split(',');
          setOptions(optionsArray);
        })
        .catch((error) => {
          console.error('Error fetching options:', error);
        });
  }, []);
  //
  useEffect(() => {
    // Make a GET request to your Flask API
    fetch('https://reports.schuepferinc.com/dbapp/trucks-data')
        .then((response) => response.text()) // Read the response as text
        .then((data) => {
          // Split the comma-separated string into an array
          const optionsArray = data.split(',');
          setTrucks(optionsArray);
        })
        .catch((error) => {
          console.error('Error fetching options:', error);
        });
  }, []);

  useEffect(() => {
    // Make a GET request to your Flask API
    fetch('https://reports.schuepferinc.com/dbapp/stone-data')
        .then((response) => response.text()) // Read the response as text
        .then((data) => {
          // Split the comma-separated string into an array
          const optionsArray = data.split(',');
          setStone(optionsArray);
        })
        .catch((error) => {
          console.error('Error fetching options:', error);
        });
  }, []);
  useEffect(() => {
    // Make a GET request to your Flask API
    fetch('https://reports.schuepferinc.com/dbapp/dirt-data')
        .then((response) => response.text()) // Read the response as text
        .then((data) => {
          // Split the comma-separated string into an array
          const optionsArray = data.split(',');
          setDirt(optionsArray);
        })
        .catch((error) => {
          console.error('Error fetching options:', error);
        });
  }, []);
  useEffect(() => {
    // Make a GET request to your Flask API
    fetch('https://reports.schuepferinc.com/dbapp/dirt-type-data')
        .then((response) => response.text()) // Read the response as text
        .then((data) => {
          // Split the comma-separated string into an array
          const optionsArray = data.split(',');
          setDirt_type(optionsArray);
        })
        .catch((error) => {
          console.error('Error fetching options:', error);
        });
  }, []);
  useEffect(() => {
    // Make a GET request to your Flask API
    fetch('https://reports.schuepferinc.com/dbapp/stab-data')
        .then((response) => response.text()) // Read the response as text
        .then((data) => {
          // Split the comma-separated string into an array
          const optionsArray = data.split(',');
          setStab(optionsArray);
        })
        .catch((error) => {
          console.error('Error fetching options:', error);
        });
  }, []);
  useEffect(() => {
    // Make a GET request to your Flask API
    fetch('https://reports.schuepferinc.com/dbapp/stab-per-data')
        .then((response) => response.text()) // Read the response as text
        .then((data) => {
          // Split the comma-separated string into an array
          const optionsArray = data.split(',');
          setStabper(optionsArray);
        })
        .catch((error) => {
          console.error('Error fetching options:', error);
        });
  }, []);

  //
  //
  //
  //

  const [daily_sheet, setDailySheet] = useState(
    {
      date_of_activity: "",
      job: "",
      weatherType: "",
      tempHigh: "",
      tempLow: "",
      precip: "",
      precipInc: "",
      narrative:"",
      trucks:"",
      stone:"",
      tons:"",
      dirt1:"",
      dirt2:"",
      dirt3:"",
      stab1:"",
      stab2:"",
      stab3:"",
      stab4:"",
    }
  );
  const url = "https://reports.schuepferinc.com/dbapp/form";

  const handleChange = (event) => {
  const {name, value} = event.target;
// console.log(name, value)
  setDailySheet((prev) => {
    return{...prev, [name]: value}
  })
}

  const history = useHistory()
  useEffect(() => {
    getJwt()
    if(!localStorage.getItem("token")) {
      history.push("/auth/login");
    }
  }, [])

  const handleSubmit = (event) => {
    event.preventDefault();
    setSubmitButtonLoading(true); // Show loading state on the button

    axios
        .post(url, {
          daily_sheet
        })
        .then((response) => {
          console.log(response.data);
          resetForm();

          // Update the button label to "Submitted" and change the color to green
          setSubmitButtonLabel("Submitted");
          setIsSubmitted(true);

          // Reset the button state after 3 seconds
          setTimeout(() => {
            setSubmitButtonLabel("Submit");
            setSubmitButtonLoading(false);
            setIsSubmitted(false);
          }, 2000);
        })
        .catch((err) => {
          console.error(err);
          setSubmitButtonLabel("Error"); // Update the button label to "Error" on error
          setSubmitButtonLoading(false); // Reset loading state on error

          // Reset the button state to "Submit" after 3 seconds
          setTimeout(() => {
            setSubmitButtonLabel("Submit");
            setIsSubmitted(false);
          }, 3000); // 3 seconds timeout
        });
  };
  return (
    <>
      <Daily_sheet_header />
      {/* Page content */}
      <Container className="mt--7" fluid>
        <Row>
          <Col className="order-xl-2 mb-5 mb-xl-0" xl="4">

          </Col>
          <Col className="order-xl-1" xl="11">
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">Job Information</h3>
                  </Col>
                  <Col className="text-right" xs="4">

                  </Col>
                </Row>
              </CardHeader>

              <CardBody>
                <Form onSubmit={handleSubmit}>
                  <h6 className="heading-small text-muted mb-4">
                   Date
                  </h6>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-calendar-grid-58" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input type="date" name="date_of_activity"
                             value={daily_sheet.date_of_activity}
                             onChange={(event) => handleChange(event)}
                      >
                      </Input>
                    </InputGroup>
                  </FormGroup>

                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    JOB
                  </h6>
                    <div className="pl-sm-2">
                      <Col lg="15">
                      <Card>
                        <Input
                            id="exampleFormControlSelect1"
                            type="select"
                            name = "job"
                            value = {daily_sheet.job}
                            onChange={(event) => handleChange(event)}
                        >
                          <option value=""></option>
                          {options.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                          ))}
                        </Input>
                      </Card>
                      </Col>
                  </div>
                  <hr className="my-4" />
                  {/* Address */}
                  <h6 className="heading-small text-muted mb-4">
                    Weather Information
                  </h6>

                  <div className="pl-sm-2">

                      <Col lg="15">
                        <FormGroup>
                          <label
                              className="form-control-label"
                              htmlFor="input-username"
                          >
                            Weather Type
                          </label>
                            <Card>
                              <Input id="exampleFormControlSelect1"
                                     name = "weatherType"
                                     value = {daily_sheet.weatherType}
                                     onChange={(event) => handleChange(event)}
                                     type="select">
                                <option></option>
                                <option>Sunny</option>
                                <option>Cloudy</option>
                                <option>Partly Sunny</option>
                                <option>Partly Cloudy</option>
                              </Input>
                            </Card>
                        </FormGroup>
                      </Col>
                  </div>
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">
                    Temperature
                  </h6>
                  <Row>
                    <Col md="6">
                      <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="example2cols1Input"
                        >
                          High
                        </label>
                        <Input
                            id="example2cols1Input"
                            placeholder="Fahrenheit"
                            name = "tempHigh"
                            value = {daily_sheet.tempHigh}
                            onChange={(event) => handleChange(event)}
                            type="number"
                        />
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="example2cols2Input"
                        >
                          Low
                        </label>
                        <Input
                            id="example2cols2Input"
                            placeholder="Fahrenheit"
                            type="number"
                            name = "tempLow"
                            value = {daily_sheet.tempLow}
                            onChange={(event) => handleChange(event)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  {/*Precipitation*/}
                  <h6 className="heading-small">
                    Precipitation
                  </h6>
                  <Row>
                    <Col md="6">
                      <FormGroup>

                        <Card>
                          <Input id="exampleFormControlSelect1" type="select"
                                 name = "precip"
                                 value = {daily_sheet.precip}
                                 onChange={(event) => handleChange(event)}
                          >
                            <option></option>
                            <option>Rain</option>
                            <option>Snow</option>
                          </Input>
                        </Card>
                      </FormGroup>
                    </Col>
                    <Col md="6">
                      <FormGroup>
                       <Input
                            id="example2cols2Input"
                            placeholder="Inches"
                            type="number"
                            name = "precipInc"
                            value = {daily_sheet.precipInc}
                            onChange={(event) => handleChange(event)}
                        />
                      </FormGroup>
                    </Col>
                  </Row>

                  {/*Precipitation*/}

                  <hr className="my-4" />
                  {/* Description */}
                  <h6 className="heading-small text-muted mb-4">Narrative</h6>
                  <div className="pl-lg-4">
                    <FormGroup>
                      <Input
                        className="form-control-alternative"
                        placeholder=""
                        rows="3"
                        defaultValue=""
                        type="textarea"
                        name = "narrative"
                        value = {daily_sheet.narrative}
                        onChange={(event) => handleChange(event)}


                      />
                    </FormGroup>

                  </div>
                 {/* Trucks */}
                  <hr className="my-4" />
                  <div className="pl-sm-2">

                    <Col lg="15">
                      <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="example2cols1Input"
                        >
                          Trucks
                        </label>
                        <Card>
                          {/*<Input id="exampleFormControlSelect1"*/}
                          {/*       name = "trucks"*/}
                          {/*       value = {daily_sheet.trucks}*/}
                          {/*       onChange={(event) => handleChange(event)}*/}

                          {/*       type="select">*/}
                          {/*  <option></option>*/}
                          {/*  <option>Sunset</option>*/}
                          {/*  <option>Palmer</option>*/}
                          {/*  <option>Ozinga</option>*/}
                          {/*</Input>*/}
                          <Input
                              id="exampleFormControlSelect1"
                              type="select"
                              name = "trucks"
                              value = {daily_sheet.trucks}
                              onChange={(event) => handleChange(event)}
                          >
                            <option value=""></option>
                            {trucks.map((option) => (
                                <option key={option} value={option}>
                                  {option}
                                </option>
                            ))}
                          </Input>
                        </Card>
                      </FormGroup>
                    </Col>


                  </div>
                 {/*  Trucks*/}
                 {/*  Stone*/}


                  <Row>
                    <Col md="6">

                        <FormGroup>
                          <label
                              className="form-control-label"
                              htmlFor="example2cols1Input"
                          >
                            Stone
                          </label>
                          <Card>
                            {/*<Input id="exampleFormControlSelect1"*/}
                            {/*       name = "stone"*/}
                            {/*       value = {daily_sheet.stone}*/}
                            {/*       onChange={(event) => handleChange(event)}*/}

                            {/*       type="select">*/}
                            {/*  <option></option>*/}
                            {/*  <option>CA-6</option>*/}
                            {/*  <option>CA-7</option>*/}
                            {/*  <option>3"</option>*/}
                            {/*  <option>PGE</option>*/}
                            {/*  <option>Rip Rap</option>*/}
                            {/*</Input>*/}
                            <Input
                                id="exampleFormControlSelect1"
                                type="select"
                                name = "stone"
                                       value = {daily_sheet.stone}
                                       onChange={(event) => handleChange(event)}
                                 >
                              <option value=""></option>
                              {stone.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                              ))}
                            </Input>
                          </Card>
                        </FormGroup>

                    </Col>
                    <Col md="6">
                      <FormGroup>
                        <label
                            className="form-control-label"
                            htmlFor="example2cols2Input"
                        >
                          Tons
                        </label>
                        <Input
                            id="example2cols2Input"
                            placeholder=""
                            type="number"
                            name = "tons"
                            value = {daily_sheet.tons}
                            onChange={(event) => handleChange(event)}

                        />
                      </FormGroup>
                    </Col>
                  </Row>

                 {/*  Stone*/}
                 {/*  Dirt*/}

                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">Dirt</h6>

                    <Row>
                      <Col md="4">
                        <FormGroup>

                          <Card>
                            {/*<Input id="exampleFormControlSelect1"*/}
                            {/*       name = "dirt1"*/}
                            {/*       value = {daily_sheet.dirt1}*/}
                            {/*       onChange={(event) => handleChange(event)}*/}

                            {/*       type="select">*/}
                            {/*  <option></option>*/}
                            {/*  <option>Import</option>*/}
                            {/*  <option>Export</option>*/}

                            {/*</Input>*/}
                            <Input
                                id="exampleFormControlSelect1"
                                type="select"
                                name = "dirt1"
                                value = {daily_sheet.dirt1}
                                onChange={(event) => handleChange(event)}
                            >
                              <option value=""></option>
                              {dirt.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                              ))}
                            </Input>
                          </Card>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Card>
                            {/*<Input id="exampleFormControlSelect1"*/}
                            {/*       name = "dirt2"*/}
                            {/*       value = {daily_sheet.dirt2}*/}
                            {/*       onChange={(event) => handleChange(event)}*/}

                            {/*       type="select">*/}
                            {/*  <option></option>*/}
                            {/*  <option>Clay</option>*/}
                            {/*  <option>Topsoil</option>*/}

                            {/*</Input>*/}
                            <Input
                                id="exampleFormControlSelect1"
                                type="select"
                                name = "dirt2"
                                value = {daily_sheet.dirt2}
                                onChange={(event) => handleChange(event)}
                            >
                              <option value=""></option>
                              {dirt_type.map((option) => (
                                  <option key={option} value={option}>
                                    {option}
                                  </option>
                              ))}
                            </Input>
                          </Card>
                        </FormGroup>
                      </Col>
                      <Col md="4">
                        <FormGroup>
                          <Card>
                            <Input
                                id="example3cols2Input"
                                placeholder="Load Count"
                                type="number"

                                name = "dirt3"
                                value = {daily_sheet.dirt3}
                                onChange={(event) => handleChange(event)}

                            />
                          </Card>
                        </FormGroup>
                      </Col>
                    </Row>

                  {/*Dirt*/}
                  {/*Stabilization*/}
                  <hr className="my-4" />
                  <h6 className="heading-small text-muted mb-4">Stabilization</h6>

                  <Row>
                  <Col md="3" sm="6">
                    <FormGroup>

                      <Card>
                        {/*<Input id="exampleFormControlSelect1"*/}
                        {/*       name = "stab1"*/}
                        {/*       value = {daily_sheet.stab1}*/}
                        {/*       onChange={(event) => handleChange(event)}*/}


                        {/*       type="select">*/}
                        {/*  <option></option>*/}
                        {/*  <option>Lime</option>*/}
                        {/*  <option>Portland</option>*/}

                        {/*</Input>*/}
                        <Input
                            id="exampleFormControlSelect1"
                            type="select"
                            name = "stab1"
                            value = {daily_sheet.stab1}
                            onChange={(event) => handleChange(event)}
                        >
                          <option value=""></option>
                          {stab.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                          ))}
                        </Input>
                      </Card>
                    </FormGroup>
                  </Col>
                  <Col md="3" sm="6">
                    <FormGroup>

                      <Card>
                        {/*<Input id="exampleFormControlSelect1"*/}
                        {/*       name = "stab2"*/}
                        {/*       value = {daily_sheet.stab2}*/}
                        {/*       onChange={(event) => handleChange(event)}*/}


                        {/*       type="select">*/}
                        {/*  <option></option>*/}
                        {/*  <option>1.5%</option>*/}
                        {/*  <option>2.0%</option>*/}
                        {/*  <option>2.5%</option>*/}
                        {/*  <option>5%</option>*/}


                        {/*</Input>*/}
                        <Input
                            id="exampleFormControlSelect1"
                            type="select"
                            name = "stab2"
                            value = {daily_sheet.stab2}
                            onChange={(event) => handleChange(event)}
                        >
                          <option value=""></option>
                          {stab_per.map((option) => (
                              <option key={option} value={option}>
                                {option}
                              </option>
                          ))}
                        </Input>
                      </Card>
                    </FormGroup>
                  </Col>
                  <Col md="3" sm="6">
                    <FormGroup>
                      <Input
                          id="example5cols3Input"
                          placeholder="Square Yards"
                          type="number"
                          name = "stab3"
                          value = {daily_sheet.stab3}
                          onChange={(event) => handleChange(event)}

                      />
                    </FormGroup>

                  </Col>
                    <Col md="3" sm="6">
                      <FormGroup>

                        <Input
                            id="example5cols3Input"
                            placeholder="Tons"
                            type="number"
                            name = "stab4"
                            value = {daily_sheet.stab4}
                            onChange={(event) => handleChange(event)}

                        />
                      </FormGroup>

                    </Col>
                  </Row>
                  {/*Stabilization*/}
                  <hr className="my-4" />

                  <div className="text-center">
                    <Button
                        color={
                          isSubmitted
                              ? "success"
                              : submitButtonLoading
                                  ? "default"
                                  : submitButtonLabel === "Error"
                                      ? "danger"
                                      : "primary"
                        }
                        className="my-4"
                        type="submit"
                        disabled={submitButtonLoading || isSubmitted}
                        onClick={handleSubmit}
                    >
                      {submitButtonLoading ? "Submitting..." : submitButtonLabel}
                    </Button>
                  </div>
                </Form>

              </CardBody>
            </Card>

          </Col>

        </Row>

      </Container>

    </>
  );
}

export default Daily_sheet;
