import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
    Button,
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    FormGroup,
    Input,
    Row
} from "reactstrap";
import UserHeader from "components/Headers/UserHeader";
import axios from "axios";

function Time_card() {
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isError, setIsError] = useState(false); // Add isError state
    const [employee, setEmployee] = useState([]);
    const [machinetype, setMachinetype] = useState([]);
    const [machinejob, setMachinejob] = useState([]);

    const url = "https://reports.schuepferinc.com/dbapp/time_card";
    const initialTimeCardState = [
        {
            date_of_activity: "",
            empName: "",
            startTime: "",
            stopTime: "",
            mstartTime: "",
            mstopTime: "",
            mType: "",
            mJob: "",
        },
    ];
    const [timeCard, setTimeCard] = useState([

        {
            date_of_activity: "",
            empName: "",
            startTime: "",
            stopTime: "",
            mstartTime: "",
            mstopTime: "",
            mType: "",
            mJob: ""
        }
    ]);


    const history = useHistory();

    useEffect(() => {
        if (!localStorage.getItem("token")) {
            history.push("/auth/login");
        }
    }, []);

    const handleChange = (event, index) => {
        const { name, value } = event.target;
        setTimeCard((prev) => {
            const updatedTimeCard = [...prev];
            updatedTimeCard[index] = { ...updatedTimeCard[index], [name]: value };
            return updatedTimeCard;
        });
    };



    const handleRemoveClick = (index) => {
        const list = [...timeCard];
        list.splice(index, 1);
        setTimeCard(list);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        axios
            .post(url, {
                timeCard
            })
            .then((response) => {
                console.log(response.data);
                setTimeCard(initialTimeCardState);
                setIsSubmitted(true); // Set the isSubmitted state to true after successful submission
                setTimeout(() => {
                    setIsSubmitted(false);
                }, 3000);

            })
            .catch((err) => {
                console.error(err);
                setIsError(true); // Set isError state to true on error
                setTimeout(() => {
                    setIsError(false);
                }, 3000);
            });
    };

    const handleAddClick = () => {
        const newTimeCard = {
            mstartTime: "",
            mstopTime: "",
            mType: "",
            mJob: ""
        };
        setTimeCard((prev) => [...prev, newTimeCard]);
    };

    useEffect(() => {
        // Make a GET request to your Flask API
        fetch('https://reports.schuepferinc.com/dbapp/employee-data')
            .then((response) => response.text()) // Read the response as text
            .then((data) => {
                // Split the comma-separated string into an array
                const optionsArray = data.split(',');
                setEmployee(optionsArray);
            })
            .catch((error) => {
                console.error('Error fetching options:', error);
            });
    }, []);

    useEffect(() => {
        // Make a GET request to your Flask API
        fetch('https://reports.schuepferinc.com/dbapp/machine-type-data')
            .then((response) => response.text()) // Read the response as text
            .then((data) => {
                // Split the comma-separated string into an array
                const optionsArray = data.split(',');
                setMachinetype(optionsArray);
            })
            .catch((error) => {
                console.error('Error fetching options:', error);
            });
    }, []);

    useEffect(() => {
        // Make a GET request to your Flask API
        fetch('https://reports.schuepferinc.com/dbapp/machine-job-data')
            .then((response) => response.text()) // Read the response as text
            .then((data) => {
                // Split the comma-separated string into an array
                const optionsArray = data.split(',');
                setMachinejob(optionsArray);
            })
            .catch((error) => {
                console.error('Error fetching options:', error);
            });
    }, []);

    return (
        <>
            <UserHeader />
            <Container className="mt--7" fluid>
                <Row>
                    <Col className="order-xl-2 mb-5 mb-xl-0" xl="4"></Col>
                    <Col className="order-xl-1" xl="8">
                        <Card className="bg-secondary shadow">
                            <CardHeader className="bg-white border-0">
                                <Row className="align-items-center">
                                    <Col xs="8">
                                        <h3 className="mb-0">Time Card Details</h3>
                                    </Col>
                                    <Col className="text-right" xs="4"></Col>
                                </Row>
                            </CardHeader>
                            <CardBody>
                                <Form onSubmit={handleSubmit}>
                                    <h6 className="heading-small text-muted mb-4">
                                        Job Date
                                    </h6>
                                    <div className="pl-sm-2">
                                        <Col lg="15">
                                            <Card>
                                                <Input type="date" name="date_of_activity"
                                                       value={timeCard[0].date_of_activity}
                                                       required
                                                       onChange={(event) => handleChange(event, 0)}
                                                />

                                            </Card>
                                        </Col>
                                    </div>
                                    <hr className="my-4" />
                                    <h6 className="heading-small text-muted mb-4">
                                        Employee Name
                                    </h6>
                                    <div className="pl-sm-2">
                                        <Col lg="15">
                                            <Card>
                                                {/*<Input type="select" name="empName"*/}
                                                {/*       value={timeCard[0].empName}*/}
                                                {/*       onChange={(event) => handleChange(event, 0)}*/}

                                                {/*>*/}
                                                {/*    <option></option>*/}
                                                {/*    <option>John Smith</option>*/}
                                                {/*    <option>Paul Smith</option>*/}
                                                {/*</Input>*/}
                                                <Input
                                                    id="exampleFormControlSelect1"
                                                    type="select"
                                                    name = "empName"
                                                    value={timeCard[0].empName}
                                                    required
                                                    onChange={(event) => handleChange(event, 0)}                                                >
                                                    <option value=""></option>
                                                    {employee.map((option) => (
                                                        <option key={option} value={option}>
                                                            {option}
                                                        </option>
                                                    ))}
                                                </Input>

                                            </Card>
                                        </Col>
                                    </div>
                                    <hr className="my-4" />
                                    <h6 className="heading-small text-muted mb-4">
                                        Time Card
                                    </h6>

                                    {/* Machine start stop time*/}
                                    {/* Start time Machine job */}
                                    <hr className="my-4" />

                                    <Row>
                                        <Col md="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="example2cols1Input"
                                                >
                                                    Start time
                                                </label>
                                                <Input
                                                    type="time"
                                                    name="startTime"
                                                    required
                                                    value={timeCard[0].startTime}
                                                    onChange={(event) => handleChange(event, 0)}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col md="6">
                                            <FormGroup>
                                                <label
                                                    className="form-control-label"
                                                    htmlFor="example2cols2Input"
                                                >
                                                    Stop Time
                                                </label>
                                                <Input
                                                    type="time"
                                                    name="stopTime"
                                                    required
                                                    value={timeCard[0].stopTime}
                                                    onChange={(event) => handleChange(event ,0)}
                                                />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {timeCard.map((singleCard, index) => (

                                        <div key={index}>

                                            {/* Machine job*/}
                                            {/* Machine start stop time*/}

                                            <hr className="my-4" />
                                            <h6 className="heading-small text-muted mb-4">
                                                Machine Time Card
                                            </h6>
                                            <hr className="my-4" />
                                            <Row>
                                                <Col md="6">
                                                    <div className="App">
                                                        <div className="services">
                                                            <div className="box">
                                                                <Row>
                                                                    <Col md="6">
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="example2cols1Input"
                                                                        >
                                                                            Machine Start time
                                                                        </label>
                                                                        <Input
                                                                            type="time"
                                                                            name="mstartTime"
                                                                            required
                                                                            value={singleCard.mstartTime}
                                                                            onChange={(event) =>
                                                                                handleChange(event, index)
                                                                            }
                                                                        />
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="example2cols1Input"
                                                                        >
                                                                            Machine Stop time
                                                                        </label>
                                                                        <Input
                                                                            type="time"
                                                                            name="mstopTime"
                                                                            required
                                                                            value={singleCard.mstopTime}
                                                                            onChange={(event) =>
                                                                                handleChange(event, index)
                                                                            }
                                                                        />
                                                                        </FormGroup>
                                                                    </Col>
                                                                    </Row>
                                                                    <Row>
                                                                    <Col md="6">
                                                                        <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="example2cols1Input"
                                                                        >
                                                                            Machine Type
                                                                        </label>
                                                                        {/*<Input*/}
                                                                        {/*    type="select"*/}
                                                                        {/*    name="mType"*/}
                                                                        {/*    value={singleCard.mType}*/}
                                                                        {/*    onChange={(event) =>*/}
                                                                        {/*        handleChange(event, index)*/}
                                                                        {/*    }*/}
                                                                        {/*>*/}
                                                                        {/*    <option></option>*/}
                                                                        {/*    <option>MIF (Melrose Park)</option>*/}
                                                                        {/*    <option>Visual Pak (Waukegan)</option>*/}
                                                                        {/*    <option>*/}
                                                                        {/*        Premier - Atlantic Packing (Elgin)*/}
                                                                        {/*    </option>*/}
                                                                        {/*</Input>*/}

                                                                            <Input
                                                                                id="exampleFormControlSelect1"
                                                                                type="select"
                                                                                name = "mType"
                                                                                required
                                                                                value={singleCard.mType}
                                                                                onChange={(event) => handleChange(event, index)}
                                                                                >
                                                                            <option value=""></option>
                                                                                {machinetype.map((option) => (
                                                                                    <option key={option} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))}
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col md="6">
                                                                        <FormGroup>
                                                                        <label
                                                                            className="form-control-label"
                                                                            htmlFor="example2cols1Input"
                                                                        >
                                                                            Machine Job
                                                                        </label>
                                                                        {/*<Input*/}
                                                                        {/*    type="select"*/}
                                                                        {/*    name="mJob"*/}
                                                                        {/*    value={singleCard.mJob}*/}
                                                                        {/*    onChange={(event) => handleChange(event, index)}*/}
                                                                        {/*>*/}
                                                                        {/*    <option></option>*/}
                                                                        {/*    <option>MIF (Melrose Park)</option>*/}
                                                                        {/*    <option>Visual Pak (Waukegan)</option>*/}
                                                                        {/*    <option>Premier - Atlantic Packing (Elgin)</option>*/}
                                                                        {/*</Input>*/}
                                                                            <Input
                                                                                id="exampleFormControlSelect1"
                                                                                type="select"
                                                                                name = "mJob"
                                                                                required
                                                                                value={singleCard.mJob}
                                                                                onChange={(event) => handleChange(event, index)}
                                                                            >
                                                                                <option value=""></option>
                                                                                {machinejob.map((option) => (
                                                                                    <option key={option} value={option}>
                                                                                        {option}
                                                                                    </option>
                                                                                ))}
                                                                            </Input>
                                                                        </FormGroup>
                                                                    </Col>
                                                                    <Col>
                                                                        <div className="my-4">
                                                                            <div className="my-4 d-flex justify-content-between">
                                                                    {index === timeCard.length - 1 && (
                                                                        <Button className="text-center" color="primary" type="submit" onClick={handleAddClick}>Add</Button>

                                                                    )}
                                                                        {index !== 0 && (
                                                                            <Button
                                                                                className="text-center"
                                                                                color="primary"
                                                                                type="button"
                                                                                onClick={() => handleRemoveClick(index)}
                                                                            >
                                                                                Remove
                                                                            </Button>
                                                                        )}

                                                                        </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    ))}
                                    <Button
                                        type="submit"
                                        color={isError ? "danger" : isSubmitted ? "success" : "primary"}
                                        disabled={isSubmitted}
                                    >
                                        {isError ? "Error" : isSubmitted ? "Submitted" : "Submit"}
                                    </Button>
                                </Form>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </>
    );
}

export default Time_card;